document.addEventListener('DOMContentLoaded', () =>
{

    const headerIcon = document.getElementById('j-header-icon') as HTMLSpanElement;
    if (headerIcon)
    {

        headerIcon.addEventListener('click', () =>
        {

            const body = document.querySelector('body') as HTMLBodyElement;
            const j_dialog_menu = document.getElementById('j-dialog-menu') as HTMLDialogElement;

            if (body && j_dialog_menu)
            {

                body.classList.toggle('u-overflow--hidden');
                j_dialog_menu.showModal();

            }

        });

    }

    const dialogClose = document.getElementById('j-dialog-menu-close') as HTMLPictureElement;
    if (dialogClose)
    {

      dialogClose.addEventListener('click', () =>
      {

        const body = document.querySelector('body') as HTMLBodyElement;
        const j_dialog_menu = document.getElementById('j-dialog-menu') as HTMLDialogElement;


        if (body && j_dialog_menu)
        {

            body.classList.toggle('u-overflow--hidden');
            j_dialog_menu.close();

        }

		});

	}

});
